<template>
  <div class="intro full">
    <van-nav-bar title="支付成功">
      <template #left>

        <div class="left_icon d_f ali_c">
          <van-icon :size="24" color="#141212" name="arrow-left" slot="left" @click="exit()" />
          <div class="cut"></div>
          <img src="../../home/payWay/img/back_home.png" alt="" class="cur_p back_home" @click="$router.push('/')">
        </div>
      </template>
    </van-nav-bar>
    <img class="ps_icon" src="../../home/payWay/img/p_success.png" alt="">
    <div class="ps_btn d_f ali_c j_s">
      <div class="ps_button cur_p" @click="gotoDetail">再次购买</div>
      <div class="ps_button empty cur_p" @click="gotoDetailOrder">订单详情</div>
    </div>
    <div class="ps_tip">
      <div class="title">温馨提示</div>
      <div class="ps_detail">
        1、 订单短信将会发送至{{phone}}，您可以点击“订单详情”，关注订单处理进度。
      </div>
      <div class="ps_detail">2、若有疑问，请联系客服电话：4008196333</div>
    </div>
  </div>
</template>

<script>
import { Toast, NavBar, Icon, CountDown } from 'vant';
export default {
    name: 'paySuccess',
    components: {
        'van-nav-bar': NavBar,
        'van-count-down': CountDown,
        'van-icon': Icon,
    },
    data() {
        return {
            phone: this.$route.query.phone,
            orderId: this.$route.query.orderId,
            commodityId: this.$route.query.commodityId,
        };
    },
    created() {
        let pat = /(\d{3})\d*(\d{4})/;
        this.phone = this.$route.query.phone.replace(pat, '$1****$2');
    },
    methods: {
        gotoDetailOrder() {
            this.$router.push(
              {
                path: '/retailOrderDetail',
                query: {
                  orderId: this.orderId,
                },
            }
            );
        },
        gotoDetail() {
            this.$router.push({
                path: '/goodsInfo',
                query: {
                    commodityId: this.commodityId,
                },
            });
        },
        exit() {
            // this.$destroy('KeepAlive')
            sessionStorage.clear();
            sessionStorage.setItem('LOGIN_STATUS', 2);
            sessionStorage.setItem('token', this.$global.token);
            this.gotoDetail();
        },
    },
};
</script>

<style scoped lang="scss">
.left_icon {
    .cut {
        width: 1px;
        height: 19px;
        background: #3e3e3e;
        border-radius: 1px;
        margin: 0 11px;
    }
    .back_home {
        width: 18px;
        height: 19px;
    }
}
.ps_tip {
    .ps_detail {
        font-size: 14px;

        font-weight: 400;
        color: #141212;
        line-height: 1.5;
    }
    .title {
        font-size: 14px;

        font-weight: 400;
        color: rgba(255, 62, 110, 1);
        margin-bottom: 20px;
    }
    padding: 0 20px 20px 20px;
    margin-top: 30px;
}
.ps_btn {
    .ps_button {
        &.empty {
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(63, 212, 103, 1);
            color: rgba(63, 212, 103, 1);
        }
        text-align: center;
        width: 130px;
        height: 40px;
        background: rgba(63, 212, 103, 1);
        border: 1px solid rgba(63, 212, 103, 1);
        border-radius: 2px;
        box-sizing: border-box;
        line-height: 40px;
        font-size: 16px;

        font-weight: bold;
        color: rgba(255, 255, 255, 1);
    }
    padding: 0 42px;
}
.ps_icon {
    display: block;
    margin: 92px auto 50px auto;
    width: 148px;
}
.points {
    margin-top: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    .box {
        background: url(../../../assets/img/bg-getPoints.png) no-repeat center
            top/100% auto;
        height: 80px;
        padding-top: 32px;
        box-sizing: border-box;
        line-height: 50px;
        font-size: 14px;
        padding-left: 80px;
        color: #0bb639;
        a {
            float: right;
            margin-right: -3px;
            img {
                height: 48px;
            }
        }
    }
}
</style>
